import React, { Component } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import ProductCard from "../ProductCard"
import ArrowIcon from "../Icons/ArrowIcon"
import LeftArrowIcon from "../Icons/LeftArrowIcon"
import { v4 } from "uuid"

const ProductSlider = styled.div`
  .card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    @media(min-width: 992px){
      min-height: 48px;
    }
  }
  .product-dtl {
    flex-direction: column;
    align-items: flex-start;
  }
  .price-drop{
    .anchor{
        border-radius:26px;
        .icon{
          width:26px;
          height:26px;
          + .text{
            margin:0 5px;
            @media(min-width: 992px){
              margin:0 10px;
            }
            @media(min-width: 1200px){
              margin:0 15px;
            }
          }
        }
        .text{
          font-size:12px;
          line-height:26px;
          @media(min-width: 576px){
            font-size:14px;
            line-height:30px;
          }
        }
      }
  }
`

const ProductItemGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
`

const SlickArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #1e4156;
  cursor: pointer;
  position:absolute;
  top:50%;
  transform: translateY(-50%);  
  width: 40px;
  height: 40px;
  @media (min-width: 992px) {
    width: 44px;
    height: 44px;
  }
  & svg{
    transition: all 0.3s ease-in-out;
    fill: #fff;
  }
  &:hover {
    background-color: #ED602B;    
  }
  &.slickNext{
    right:-20px;
    @media (min-width: 992px) {
      right:-22px;
    }
  }
  &.slickPrev{
    left:-20px;
    @media (min-width: 992px) {
      left:-22px;
    }
  }
`

function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class RelatedProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.renderSliderContent = this.renderSliderContent.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  renderSliderContent() {
    const { data, location } = this.props
    if (data.length < 1) {
      return <ProductItemGrid>There is no Relative Product</ProductItemGrid>
    }

    return data.map((item, i) => (
      <ProductItemGrid key={v4()}>
        <ProductCard data={item} order={i} key={i} location={location} />
      </ProductItemGrid>
    ))
  }
  render() {
    const { data } = this.props
    let settings = {}
    if (data.length < 2) {
      settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
      }
    } else if (data.length === 2) {
      settings = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 500,
      }
    } else {
      settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        speed: 500,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
    }

    return (
      <div className="container">
        <ProductSlider className="product-slider">
          <Slider {...settings} ref={c => (this.slider = c)}>
            {this.renderSliderContent()}
          </Slider>
          <SlickArrow className="slickPrev" onClick={this.previous}><LeftArrowIcon /></SlickArrow>
          <SlickArrow className="slickNext" onClick={this.next}><ArrowIcon /></SlickArrow>
        </ProductSlider>
      </div>
    )
  }
}

export default RelatedProductCarousel
